import { Link } from 'react-router';
import { Route } from '~/config/enums';
import { renderIcon } from './RobinIcons';

interface RobinLogoProps {
  clickable?: boolean;
}

export default function RobinLogo({ clickable = true }: RobinLogoProps) {
  return (
    <Link to={clickable ? Route.Home : ''}>
      <button className="flex justify-center items-center gap-1 px-2">
        {renderIcon('logo', 10)}

        <div className="flex gap-1 items-end">
          <h1 className="flex robin-regular-24">RobinFeed</h1>
        </div>
      </button>
    </Link>
  );
}
